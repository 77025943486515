.region-table-filter {
  .ratios-categories-wrapper {
    position: relative;
    top: 0;

    .scroll-wrapper {
      max-height: 270px;
    }
  }
}
