@import 'src/styles/colors';

.canceled-issue-history {
  padding: 16px 24px;

  &-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 8px;
  }

  &-empty-text {
    color: $manatee;
  }
}
