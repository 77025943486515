@import '../../../styles/mixins';

.import-table-wrapper {
  display: inline-block;
  position: relative;
  height: 100%;
  flex-grow: 1;
}

.import-table-container {
  bottom: 0;
  overflow: auto;
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 300px;
  @include scrollbar();
}

.import-table-similar-issues-select {
  width: 130px;
}

.import-table-similar-issues-link {
  text-decoration: underline;
  margin-bottom: 8px;
}

.import-table-similar-issues-not-found {
  color: rgb(138, 146, 166);
}

.import-table-save-button {
  margin-left: 16px !important;
}

.import-table-cell-description {
  & pre {
    margin: 0;
    white-space: pre-wrap;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    //font-size: 1rem;
    //line-height: 1.5;
    letter-spacing: 0.00938em;
  }
}
