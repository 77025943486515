@import '../../../../../styles/mixins';
.search-items {
	position: relative;
	z-index: 3;
	.items-list {
		max-height: 400px;
		overflow-y: auto;
		margin-left: -10px;
		margin-right: -10px;
		@include scrollbar();
	}
	.search-item {
		cursor: pointer;
		padding: 10px;
		&:hover {
			background: rgba(0, 0, 0, 0.04);
		}
		p {
			font-size: 14px;
		}
		.search-item-helper-text {
			font-size: 12px;
			color: #949494;
		}
		mark {
			color: #1976d2;
			background: none;
		}
	}
}
