@import 'src/styles/colors';

.edit-button {
  background-color: $gallery !important;
  height: 24px;
  width: 24px;

  &-icon {
    color: $manatee;
    font-size: 14px !important;
  }
}
