@import 'src/styles/colors';

.workers-list-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow: auto;
  border-radius: 5px;
  border: 1px solid $light-gray;
  margin-bottom: 20px;
  height: 100%;

  .worker-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    &:not(:last-child) {
      border-bottom: 1px solid $light-gray;
    }

    .worker {
      display: flex;
      align-items: center;
      min-height: 50px;

      .avatar {
        margin-right: 20px;
      }

      .worker-data {
        .worker-id {
          font-size: 12px;
          color: $manatee;
        }
      }
    }
  }
}

.empty-list {
  text-align: center;
  margin-bottom: 20px;
  min-height: 350px;
}
