@import '../../styles/variables';
.loader-container {
	background: rgba(255, 255, 255, 0.6);
	position: absolute;
	z-index: 2;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.lds-dual-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
	position: absolute;
	top: calc(50% - 40px);
	left: calc(50% - 40px);
}
.lds-dual-ring:after {
	content: ' ';
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid blue;
	border-color: blue transparent blue transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
