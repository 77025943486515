@import 'src/styles/colors';

.edit-issue-status-to-master-search-dialog-title {
  display: flex;
  align-items: center;

  .title-icon {
    margin-right: 15px;
    width: 36px;
    height: 36px;
  }

  .title-text {
    font-size: 20px;
  }
}
