@import 'src/styles/colors';

.worker-card {
	.worker-info {
		display: flex;
	}
	.worker-card-info {
		display: flex;
	}
	.worker-card-left {
		flex-shrink: 2;
		flex-grow: 0;
		flex-basis: 400px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 30px;

		.worker-rating {
			background-color: $selective-yellow;
			padding: 9px 18px;
			border-radius: 100px;
			display: flex;
			align-items: center;
			gap: 24px;

			.worker-rating-star-value-wrapper {
				font-weight: bold;
				display: flex;
				align-items: center;
				gap: 8px;

				.rating-star {
					color: $gold;
					font-size: 15px;
				}
			}
		}
	}
	.worker-card-center {
		flex-grow: 1;
	}
	.worker-card-right {
		flex-grow: 3;
		padding: 0 30px;
		.chat-wrapper {
			.chat {
				border-radius: 8px;
			}
			height: 500px;
			border: 1px solid #adb5bd;
			border-radius: 8px;
			.messages-wrapper {
				background: #f0f2f5;
			}
			.add-message-form {
				border-top: 1px solid #adb5bd;
			}
		}
	}
	.worker-detail-info {
		display: flex;
		flex-direction: column;
		gap: 30px;

		.worker-detail-info-item {
			display: flex;
			align-items: center;

			> div {
				&:first-child {
					color: $manatee;
					font-size: 14px;
					min-width: 150px;
					flex-shrink: 0;
				}

				&:last-child {
					font-weight: 500;
					width: 100%;
				}
			}
		}
	}
	.done-today-balance-wrapper {
		display: flex;
		align-items: center;
		max-height: 80px;
		min-width: 300px;
		margin-bottom: 20px;
		.worker-done-today,
		.worker-balance {
			flex: 1 1;
			padding: 0 16px;
			text-align: center;

			.worker-done-value,
			.worker-balance-value {
				font-size: 20px;
				font-weight: bold;
			}

			.worker-done-caption,
			.worker-balance-caption {
				color: $manatee;
				font-size: 12px;
			}
		}
	}
}

@media (max-width: 1200px) {
	.worker-card-info {
		flex-direction: column;
	}
}
