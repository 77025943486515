.price-wrapper {
	margin-top: 18px;
	padding: 12px;
	background: rgb(235, 235, 235);
	border-radius: 10px;
	.price-header {
		display: flex;
		justify-content: space-between;
		font-weight: 600;
		font-size: 16px;
	}

	.price-name {
		margin-right: 20px;
	}

	.price-right {
		display: flex;
		align-items: center;
		min-width: 150px;
		justify-content: flex-end;

		button {
			margin-left: 10px;
		}
	}

	.price-content {
		margin-top: 10px;
	}
}
