.import-gsis-page-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;

	.import-gsis-page-header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;
	}
}
