.notification-item {
	position: relative;
	display: flex;
	align-items: center;
	padding: 10px;
	cursor: pointer;
	&.unSeen {
		background: rgb(247, 248, 255);
	}
	&:hover {
		background: rgba(0, 0, 0, 0.04);
	}
	.notification-item-data {
		margin-left: 10px;
		>div:first-child {
			color: #232d42;
			display: flex;
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 10px;
		}
		>div:last-child {
			color: #adb5bd;
			font-size: 12px;
			text-align: start;
		}
	}
	.status-ws-data {
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 10px;
		margin-bottom: 10px;
	}
	.notification-link{
		position: relative;
		display: flex;
		align-items: center;
		text-decoration: none;
	}
}
