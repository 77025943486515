@import 'src/styles/colors';

.message_2 {
	box-sizing: border-box;
	display: flex;
	gap: 8px;
	background-color: #e1e4fa;
	border-radius: 18px;
	color: #232d42;

	&.backoffice {
		background-color: #f5f7fc;
		margin-left: 40px;
	}

	&.worker {
		margin-right: 40px;
	}

	&.client {
		margin-right: 40px;
	}

	&.system {
		background-color: #161c46;
		color: #e1e4fa;
	}

	.message-content {
		width: 100%;
		padding: 6px 12px 6px 12px;

		font-size: 14px;
		cursor: default;

		&-item {
			display: flex;
			align-items: center;
			gap: 5px;
		}

		&.interlocutor {
			border-bottom-left-radius: 0;
			margin-right: 20px;
		}

		&.user {
			border-bottom-right-radius: 0;
			margin-left: 20px;
		}

		.sender-name {
			font-size: 12px;
			font-weight: bold;
			color: #232d42;
			&.interlocutor {
				color: #2962ff;
			}
			&.system {
				color: #e1e4fa;
			}
		}

		.point-divider {
			width: 3px;
			height: 3px;
			border-radius: 50%;
			background: $manatee;
			align-self: center;
			margin-left: 5px;
			margin-right: 5px;
		}

		.time {
			color: $manatee;
			font-size: 12px;
		}

		.photo-files {
			display: flex;
			flex-wrap: wrap;
			gap: 6px;

			.image-wrapper {
				position: relative;
				cursor: pointer;
				margin-top: 8px;

				.image {
					width: 120px;
					height: 120px;
					border-radius: 5px;
					object-fit: cover;
				}
				.file {
					width: 60px;
					height: 60px;
				}
				.date {
					color: $white;
					position: absolute;
					bottom: 7px;
					left: 6px;
					font-size: 10px;
				}
			}
		}

		.text {
			font-size: 14px;
			cursor: text;
			word-break: break-word;
			margin-top: 2px;
			white-space: pre-wrap;
		}

		.message-meta {
			position: relative;
			right: -4px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 4px;
			margin-bottom: 2px;

			&.interlocutor {
				color: $manatee;
			}

			&.user {
				color: $cornflower-blue;
			}

			.seen-badge {
				display: flex;

				.unseen {
					color: $manatee;
				}

				&.interlocutor {
					display: none;
				}
			}
		}

		.more-popover {
			display: flex;
			height: 20px;
			margin-left: auto;
			align-items: center;
		}
	}
}
