.support-page- {
	&wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&header {
		display: flex;
		justify-content: space-between;
	}

	&main {
		display: flex;
		gap: 16px;
		height: 100%;
		position: relative;
		overflow: hidden;
	}
}

.chats-users-group {
	width: 30%;
	position: relative;
	border-radius: 4px;
	overflow: hidden;
}

.chats-users-chat {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	height: 100%;
	position: relative;
}

.choose_chat {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	.text {
		padding: 7px 15px;
		background-color: #e1e4fa;
		border-radius: 50px;
	}
}

.issue-history-wrapper {
	height: 45%;
	padding-top: 16px;
}
