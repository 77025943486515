div.dv-photo-files-preview {
  padding-right: 0;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 350px;

        a {
        position: relative;
        display: inline-block;
        width: 70px;
        height: 70px;
        border-radius: 6px;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.14);
        box-shadow: 0 0 8px 1px rgba(34, 60, 80, 0.2);

        &:hover {
          .dv-pf-play-icon {
            color: #7b1fa2
          }
        }
      }

      .dv-pf-play-icon {
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
        width: 30px;
        height: 30px;
        transition: all linear 200ms;
      }

      img {
        width: 70px;
        height: 70px;
      }
}