$white: #ffffff;
$black: #000000;
$light-black: #00000014;
$athens-gray: #f0f2f4;
$selective-yellow: #ffb80021;
$gold: #ffd200;
$mountain-meadow: #1aa053ff;
$gallery: #efefef;
$picton-blue: #48c1f4;
$selago: #eaedfb;
$lightBlue: #0040f1;

// Text colors
$ebony-clay: #232d42;
$manatee: #8a92a6;
$denim: #1976d2;
$royal-lue: #3a57e8;
$athens-gray: #f0f2f4;
$cornflower-blue: #3a57e8;
$ebony-clay: #232d42;
$port-gore: #212342;
$silver-chalice: #ababab3f;
$dusty-gray: #949494;
$dusty-gray_opacity: #94949454;
$light-gray: #e9ecef;
