.map-page-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	.map-page-header {
		display: flex;
	}
	.map-page-content {
		display: flex;
		gap: 20px;
		overflow: auto;
		height: 100%;
		@media (max-width: 998px) {
			flex-direction: column;
		}
	}
}
