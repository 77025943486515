.create-device-photo{
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  margin-top: 20px;
  .upload-files-wrapper{
    ul{
      margin: 0;
    }
  }
}