.wrapper {
	margin: 0;

	span {
		display: inline-block;
		color: #9e9e9e;
	}
}

.rating {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 16px;
}

.reasons {
	.reasonsWrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 5px;
		margin: 10px 0;

		div {
			padding: 4px 6px;
			border-radius: 2px;
			background: #eaedfb;
		}
	}
}

.comment {
	margin: 10px 0 0 0;
	white-space: pre-wrap;
	font-size: 14px;
	font-weight: 500;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.photos {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	div {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		margin-bottom: 10px;

		img {
			width: 100px;
			height: 100px;
			object-fit: cover;
			border-radius: 16px;
		}
	}
}

.audios {
	margin-bottom: 10px;
}
