@import '../../styles/mixins';

.workers-page-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;

	.workers-page-header {
		display: flex;
		justify-content: space-between;
	}
}
