@import '../../../styles/mixins';

.ratios-categories-wrapper {
	position: absolute;
	top: 44px;
	left: 0;
	z-index: 3;
	.categories-accordion {
		box-shadow: none;
		border-radius: 0;
	}
	.scroll-wrapper {
		max-height: 300px;
		overflow-y: auto;
		@include scrollbar();
	}
}
