@import 'src/styles/colors';

.dv-pr-list {
  .dv-pr-item {
    > div.dv-issue-act-nrp {
      padding-right: 0;
      display: flex;
      align-items: flex-start;
      gap: 8px;
      flex-wrap: wrap;
      max-width: 350px;
    }
  }
}

.dv-issue-act-nrp-edit {
  display: flex;
  gap: 8px;

  .dv-issue-act-nrp-edit-clear{
    position: absolute;
    left: 45px;
    top: -13px;
  }
}

.dv-issue-act-nrp-preview {
  width: 70px;
  height: 70px;
  border-radius: 6px;
  overflow: hidden;
  background: rgb(255 255 255 / 14%);
  box-shadow: 0 0 8px 1px rgba(34, 60, 80, 0.2);
  color: $silver-chalice;

  img {
    width: 100%;
    height: 100%;
  }
}

.dv-issue-act-nrp-preview-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    font-size: 2rem;
  }
}

.act-nrp-files {
  img {
    width: 70px;
    height: 70px;
  }

  .save-remove-buttons {
    position: absolute;
    top: -8px;
    right: -30px;

    svg {
      font-size: 15px;
    }
  }
}