.edit-worker-schedule-form-wrapper {
  padding: 20px 0;

  .divider {
    margin: 16px 0;
  }

  .time-pickers {
    display: flex;
    align-items: center;
    gap: 15px;

    .divider {
      width: 15px;
    }
  }
}
