@import 'src/styles/colors';

.edit-worker-specializations-dialog-title {
	display: flex;
	align-items: center;

	.title-icon {
		margin-right: 30px;
		width: 36px;
		height: 36px;
		color: $picton-blue;
	}

	.title-text {
		font-size: 20px;
	}
}

.close-icon-box {
	display: flex;
	justify-content: right;
	cursor: pointer;
}
