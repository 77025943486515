@import 'src/styles/colors';

.edit-issue-reminder-dialog-title {
  display: flex;
  align-items: center;

  .title-icon {
    margin-right: 30px;
    width: 36px;
    height: 36px;
    color: $picton-blue;
  }

  .title-text {
    font-size: 20px;
  }
}
