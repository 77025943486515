.upload-files-wrapper {
  display: flex;

  .dropzone-inner {
    position: relative;
    width: 100px;
    border: 1px dashed #3a57e8;
    cursor: pointer;
    height: 100px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    background-color: rgba(58, 87, 232, 0.1);

    .dropzone-note {
      padding: 80px 0 10px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  ul {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding-left: 0;

    li {
      list-style: none;
    }
  }

  .file-preview {
    position: relative;
    width: 100px;
    border: 1px dashed #3a57e8;
    cursor: pointer;
    height: 100px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    background-color: rgba(58, 87, 232, 0.1);

    .img-preview-wrapper {
      cursor: default;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;
      border-radius: 0.5rem;

      img {
        display: inline;
        width: 100%;
        height: auto;
      }

      .file-name {
        position: absolute;
        bottom: 0;
        max-width: 100%;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0.1rem 0.1rem 1.2rem;
        font-size: 0.8rem;
        color: blue;
        background: rgba(255, 255, 255, 0.7);
        transform: translateY(3.3rem);
        transition: all linear 300ms;
      }

      &:hover {
        .file-name {
          transform: translateY(0.1rem);
        }
      }
    }
  }

  .delete-file {
    position: absolute;
    font-size: 0;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    padding: 0;
    right: -0.4rem;
    top: -0.4rem;
    z-index: 1;
    background: #fff;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.12);

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      display: block;
      width: 11px;
      height: 11px;
      border-left: 0.1rem rgba(0, 0, 0, 0.12) solid;
      transform: rotate(45deg);
    }

    &:after {
      content: '';
      position: absolute;
      top: 5px;
      left: -2px;
      display: block;
      width: 11px;
      height: 11px;
      border-right: 0.1rem rgba(0, 0, 0, 0.12) solid;
      transform: rotate(-45deg);
    }

    &:hover {
      border-color: red;

      &:before,
      &:after {
        border-color: red;
      }
    }
  }
}
