@import 'src/styles/variables';

.main-content {
	padding: 20px 20px 10px;
	position: relative;
	height: calc(100vh - $topBarHeight);
	// min-height: 600px;
	overflow: hidden;
}

.main-layout {
	padding-top: $topBarHeight;
	padding-left: $widthSidebar;
	transition: all 200ms ease-in;
	background: rgb(233, 236, 239);
	@media (max-width: 998px) {
		padding-top: 0;
	}
}

.main-layout.side-bar-is-open {
	padding-left: $widthSidebarIsOpen;
}
