.edit-worker-specialization-form-wrapper {
  padding: 20px 0;

  .specialization-options-container {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    max-height: 500px;
    overflow: auto;
  }
}
