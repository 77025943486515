@import 'src/styles/colors';

.files-wrapper {
	.file-name {
		color: $cornflower-blue;
		text-align: left;
	}
	.file-data {
		display: flex;
		align-items: center;
		margin: 15px 0;
		column-gap: 10px;
	}
	.file-preview {
		width: 70px;
		height: 70px;
		object-fit: cover;
	}
}
