.search-top-bar {
	width: 100%;
}

.search-drop-down {
	position: absolute;
	top: 44px;
	left: 0;
	width: 100%;
	min-height: 100px;
	padding: 10px;
	display: none;
	z-index: 10;
}

.search-drop-down.isOpen {
	display: block;
}
