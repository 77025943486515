.groups-header_btn-wrapper {
  .MuiButtonBase-root {
    background-color: transparent;
    box-shadow: none !important;
    padding: 10px;
    border-radius: 50%;
    min-width: 100%;
    min-height: 100%;

    &:hover {
      background-color: #d2d2d2 !important;
    }

    .MuiButton-iconSizeMedium {
      margin: 0;
    }
  }
}
