.order {
  margin-bottom: 20px;

  &-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  &-photos {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .parts-list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    .part {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 2px solid rgba(224, 224, 224, 1);
      padding: 10px;
      border-radius: 10px;

      &-name {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      &_desc {
        display: flex;

        > div {
          &:first-child {
            color: rgb(138, 146, 166);
            font-size: 14px;
            min-width: 150px;
          }
        }
      }

      &-photo {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        overflow: hidden;

        .dv-photo-files {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &-info {
        max-width: 300px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-status {
        border: 1px solid blue;
        padding: 5px;
      }
    }
  }
}
