.table-filter-wrapper{
  display: none;
  position: absolute;
  top: 31px;
  left:0;
  z-index: 3;
  max-height: calc(100vh - 460px);
  width: max-content;
  overflow: auto;
}
.table-filter-wrapper.isOpen{
  display: block;
}
