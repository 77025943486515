.worker-filter-label{
  position: relative;
  display: flex;
  align-items: center;
  .filter-count{
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    padding-top: 5px;
    width: 25px;
    height: 25px;
    background: rgb(252, 90, 90);
    margin-left: 6px;
    border-radius: 50%;
    font-size: 13px;
  }
}