@import 'src/styles/mixins';

.groups-wrapper {
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 3;
  width: 300px;

  .scroll-wrapper {
    max-height: 350px;
    overflow-y: auto;
    @include scrollbar();
  }
}
