@import '../../../../styles/mixins';

.prices-table-wrapper {
  display: inline-block;
  position: relative;
  height: 100%;
  flex-grow: 1;
  background: transparent !important;
  box-shadow: none !important;

  .prices-table-container {
    bottom: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 300px;
    @include scrollbar();
  }
}
