@import '../../../styles/variables';
@import '../../../styles/colors';

.side-bar {
	width: $widthSidebar !important;
	height: calc(100vh - $topBarHeight);
	position: fixed;
	left: 0;
	transition: all linear 300ms !important;
	padding: 0 10px;
	@media (max-width: 998px) {
		top: $topBarHeight;
	}
	a.nav-link {
		text-decoration: none;
		color: $manatee;
		transition: all linear 300ms;

		li {
			border-radius: 5px;
			margin: 5px 0;

			.MuiListItemIcon-root {
				color: $manatee;
			}
		}
	}

	a.nav-link.active {
		color: $white;

		li {
			background: $cornflower-blue;

			.MuiListItemIcon-root {
				color: $white;
			}
		}
	}

	&.is-open {
		width: $widthSidebarIsOpen !important;
		.side-bar-count {
			margin-left: 6px;
		}
	}
	.side-bar-count {
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 25px;
		height: 25px;
		background: rgb(252, 90, 90);
		margin-left: 0;
		border-radius: 50%;
		font-size: 13px;
	}
}
