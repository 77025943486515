@import '../../../styles/mixins';

.issues-table-wrapper {
	height: 100%;
	position: relative;
	overflow: auto;
}

.reminders-list {
	.reminders-item {
		font-weight: 500;
		font-size: 10px;
		line-height: 14px;
		color: #ffffff;
	}
}
