@import 'src/styles/colors';
@import 'src/styles/variables';

.accordion {
	&:before{
		display: none;
	}

	.accordion-summary {
		background-color: $athens-gray;
		border-radius: 6px;
		padding-left: 10px;
		height: 50px;
		color: $ebony-clay;

		.MuiAccordionSummary-expandIconWrapper {
			color: $ebony-clay;
		}

		.title {
			font-weight: 500;
			font-size: 16px;
		}
	}

	.accordion-details {
		font-size: 14px;
		padding: 24px 0 0;

		.MuiAccordionDetails-root {
			padding: 0;
		}
	}
}
