.edit-issue-reminder-form-wrapper {
  padding: 20px 0;

  .date-time {
    margin-top: 20px;

    &-title {
      font-weight: bold;
      margin-bottom: 12px;
    }

    &-pickers {
      display: flex;
      gap: 16px;
    }
  }

  .send-only-bo-form-control {
    margin-top: 20px;
  }
}
