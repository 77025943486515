.issue-created-wrapper {
	.header-wrapper {
		margin-top: 100px;
		display: flex;
		align-items: center;
	}

	.issue-created-message {
		margin-top: 24px;
		max-width: 509px;
		font-weight: 400;
		font-size: 20px;
		color: rgba(27, 32, 64, 0.5);
	}
}
