.cm-workers {
  max-height: 350px;
  min-height: 400px;
  height: 100%;
  overflow-y: auto;

  .cm-worker {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px;
    border: 1px solid rgb(233, 236, 239);
    border-bottom: 0;

    &:last-child {
      border-bottom: 1px solid rgb(233, 236, 239);
    }

    &:hover {
      background: aliceblue;
    }
  }
}


.empty-list {
  text-align: center;
  margin-bottom: 20px;
  min-height: 350px;
}
