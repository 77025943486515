@import '../../../../styles/mixins';
.notifications-wrapper {
	position: relative;
	.notifications-btn {
		position: relative;
	}
	.notifications-popover {
		position: absolute;
		top: 38px;
		right: 33px;
		display: none;
		z-index: 3;
		min-width: 444px;
	}
	.notifications-popover.isOpen {
		display: block;
	}
	.notifications-header {
		padding: 16px;
	}
	.mark-as-read-btn {
		display: block;
	}
	.notifications-list {
		max-height: 490px;
		overflow-y: auto;
		@include scrollbar();
	}
	.ntf-btn-pulse {
		position: absolute;
		right: 5px;
		top: 5px;
		height: 10px;
		width: 10px;
		display: block;
		border-radius: 50%;
		background-color: #f44336;
		animation: pulse 1500ms infinite;

		@keyframes pulse {
			0% {
				box-shadow: #f44336 0 0 0 0;
			}
			75% {
				box-shadow: #ff69b400 0 0 0 10px;
			}
		}
	}
}
