.dv-photo-files-edit {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .photo-file-wrapper {
    width: 70px;
    height: 70px;
    position: relative;
    display: inline-block;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 8px 1px rgba(34, 60, 80, 0.2);
  }

  .photo-file-delete {
    position: absolute;
    top: -13px;
    right: -12px;
  }
}

.photo-files-issue {
  img {
    width: 70px;
    height: 70px;
  }

  .save-remove-buttons {
    position: absolute;
    top: -8px;
    right: -30px;

    svg {
      font-size: 15px;
    }
  }
}