.invoicing-notifications-wrapper{
  .notification-btn-item{
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    >div:first-child{
      min-width: 100px;
      color: #9e9e9e;
    }
    >div:last-child{
      min-width: 300px;
    }
  }
  .notification-date{
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
}