@import '../../../styles/mixins';

.issue-detail-wrapper {
	position: relative;
	max-height: 100%;
	overflow-y: auto;
	padding: 20px;
	flex: 1 1;
	@include scrollbar();
}

.chat-btn {
	position: fixed !important;
	right: 20px;
	bottom: 20px;
	background-color: #0040f1 !important;

	& .icon {
		color: #fff;
		font-size: 28px;
	}

	& .badge-inner {
		width: 42px;
		height: 42px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
