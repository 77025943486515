.filter-app-popover {
	width: 400px;
	margin-top: 8px;
	padding: 10px;
	border: 1px solid rgba(0, 0, 0, 0.06);
	box-shadow: 0 10px 13px rgba(17, 38, 146, 0.05);
	border-radius: 8px;
	overflow: hidden;
}

.filter-item-type {
	padding: 14px;
	min-height: 38px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
}

.filter-item-wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
}
