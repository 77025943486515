@import 'src/styles/colors';

.emty-moy-sklad-container {
	display: flex;
	justify-content: space-between;
}

.moy-sklad-container {
	padding: 16px 24px;

	&-title {
		font-weight: bold;
		font-size: 20px;
		margin-bottom: 8px;
	}

	&-empty-text {
		color: $manatee;
	}
}

.container-empty-moy-sklad-text {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 172px;
}

.empty-moy-sklad-text {
	width: 267px;
	height: 42px;
	padding: 10px 17px;
	font-size: 14px;
	background-color: #e1e4fa;
	border-radius: 50px;
	font-weight: 500;
	line-height: 25px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

//Полный склад
.store-title-and-button {
	display: flex;
}

.worker-store {
	padding: 16px 24px;

	&-title {
		display: flex;
		align-items: center;
		gap: 16px;
		margin-bottom: 24px;

		.title-text {
			font-weight: bold;
			font-size: 20px;
		}
	}
}

.table-head-title {
	font-size: 12px;
	line-height: 21px;
	font-weight: 600;
	color: #878ea5;
	text-transform: uppercase;
}
