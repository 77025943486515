@import 'src/styles/colors';

.issue-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 54px;
	gap: 16px;

	.issue {
		&-created-at {
			color: $manatee;
		}

		&-title {
			font-weight: bold;
			flex-grow: 1;
		}

		&-arrow {
			color: $manatee;
		}
	}
}
