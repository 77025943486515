@import 'src/styles/colors';

.canceled-issue-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  gap: 16px;
  font-size: 14px;

  .issue {
    &-created-at {
      color: $manatee;
    }

    &-title {
      width: 250px;
    }

    &-cancel-comment {
      flex-grow: 1;
    }
  }
}
