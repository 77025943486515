@import 'src/styles/mixins';

.partners-wrapper {
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 3;
  width: 300px;

  .scroll-wrapper {
    max-height: 350px;
    overflow-y: auto;
    @include scrollbar();

    .partners-container {
      padding: 14px;
      min-height: 38px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      cursor: pointer;

      .partner {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
