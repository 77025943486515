.archive-workers-filters-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .extra-filters {
    display: flex;
    gap: 20px;
  }
}
