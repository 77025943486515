@import '../../styles/mixins';

.main-partner {
	height: 100vh;
	background: #f6f6fb;
	overflow-y: auto;
	@include scrollbar();
}

.partner-content {
	margin: 0 auto;
	max-width: 840px;
	padding: 80px 20px;
	@media (max-width: 998px) {
		padding: 20px;
	}
	.header-top {
		display: flex;
		justify-content: space-between;
	}
}
