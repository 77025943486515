@import '../../styles/mixins';

.MuiDialog-container.MuiDialog-scrollBody {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .MuiDialog-paper{
  &:not(input) {
    background-color: #F8F8F8 !important;
  }
}

  .MuiDialog-paperWidthLg {
    margin: 80px 15px;
    max-width: 800px;
    min-width: 400px;
  }

  .MuiDialogContent-root {
    min-height: max-content;
  }
}

.dialog__overlay {
  position: fixed;
  background-color: rgba(6, 0, 20, 0.5);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  z-index: 9999;
  justify-content: center;
  align-items: flex-start;

  .dialog {
    margin: 80px 15px;
    padding: 30px 50px;
    background-color: #fff;
    position: relative;
    border-radius: 20px;

    &:not(input) {
      background-color: #F8F8F8 !important;
    }

    .dialog__header {
      display: flex;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0;

      .dialog-title {
        font-size: 1.5rem;
        font-weight: 500;
      }

      .dialog__close {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;

        svg {
          path {
            stroke: gray;
          }
        }
      }
    }

    .dialog__content {
      margin: 30px 0 30px 0;
      font-size: 18px;
      text-align: center;
    }

    .dialog__footer {
      display: flex;
      justify-content: center;

      .dialog__ok-button {
        margin-left: 16px;
      }
    }
  }
}

.dialog__overlay.big-size {
  .dialog {
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
    overflow-y: auto;
    @include scrollbar();
  }
}

.close-icon {
  position: absolute;
  right: 15px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
