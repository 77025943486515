.workers-filters-wrapper {
  margin-bottom: 24px;

  .extra-filters {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .filter {
      margin-right: 20px;
    }
  }
}
