.issue-comments-wrapper {
	padding: 10px;
	max-width: 280px;
	width: 100%;
	overflow: auto;
}
@media (max-width: 998px) {
	.issue-comments-wrapper {
		max-width: none;
	}
}
