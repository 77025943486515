@import 'src/styles/colors';

.message {
  box-sizing: border-box;
  display: flex;
  gap: 8px;

  &.user {
  }

  .avatar {
    width: 30px;
    height: 30px;
    margin-top: 5px;

    &.user {
      display: none;
    }
  }

  .message-content {
    width: 100%;
    padding: 8px 12px 4px;
    border-radius: 16px;
    color: $black;
    font-size: 16px;
    cursor: default;

    &-item {
      display: flex;
      gap: 5px;
    }

    &.interlocutor {
      border-bottom-left-radius: 0;
    }

    &.user {
      border-bottom-right-radius: 0;
    }

    .sender-name {
      font-size: 14px;
      font-weight: bold;

      &.interlocutor {
        color: $cornflower-blue;
      }
    }

    .point-divider {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: $manatee;
      align-self: center;
      margin-left: 5px;
      margin-right: 5px;
    }

    .time {
      color: $manatee;
      font-size: 14px;
    }

    .photo-files {
      display: flex;
      flex-wrap: wrap;

      .image-wrapper {
        position: relative;

        .image {
          width: 120px;
          height: 120px;
          border-radius: 5px;
          margin: 15px 15px 0 0;
          object-fit: cover;
        }
        .date {
          color: $white;
          position: absolute;
          bottom: 7px;
          left: 6px;
          font-size: 10px;
        }
      }
    }

    .text {
      font-size: 14px;
      cursor: text;
      word-break: break-word;
    }

    .message-meta {
      position: relative;
      right: -4px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;

      &.interlocutor {
        color: $manatee;
      }

      &.user {
        color: $cornflower-blue;
      }

      .seen-badge {
        display: flex;

        .unseen {
          color: $manatee;
        }

        &.interlocutor {
          display: none;
        }
      }
    }

    .more-popover {
      display: flex;
      height: 20px;
      margin-left: auto;
      align-items: center;
    }
  }
}
