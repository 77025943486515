.sidebar-map-wrapper {
	overflow: hidden;
	max-width: 430px;
	width: 100%;
}
@media (max-width: 998px) {
	.sidebar-map-wrapper {
		max-width: none;
	}
}
