@import '../../styles/mixins';

.service-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.service-page-header {
  display: flex;
  justify-content: space-between;
}
