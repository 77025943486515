@import 'src/styles/colors';

.verification-block {
  padding: 10px;
  border: 1px solid $light-gray;
  border-radius: 10px;
  max-width: 250px;

  .status-title {
    position: relative;

    .open-put-statuses-button {
      display: flex;
      width: 100%;
      justify-content: space-between;
      cursor: pointer;
    }

    .put-statuses {
      display: none;
      position: absolute;
      padding: 10px;
      background: white;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.2) 0 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
      right: 0;
      min-width: 200px;
      z-index: 3;

      .btn-status {
        margin-top: 10px;
      }

      .btn-status:first-child {
        margin-top: 0;
      }
    }

    .put-statuses.isOpen {
      display: block;
    }
  }

  .status-description {
    margin: 10px 0 0 20px;
    font-size: 14px;
    color: $manatee;
  }

  .status-icon-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
}

@media(max-width: 998px) {
  .verification-block {
    max-width: 100%;
  }
}
