@import 'src/styles/colors';

.information {
  margin-top: 20px;

  .information-accordion {
    background-color: transparent;
    box-shadow: none;

    &-summary {
      background-color: $light-gray;
      border-radius: 10px;
      min-height: 40px !important;

      .Mui-expanded {
        margin: 12px 0;
      }

      .information-title {
        margin: 0;
      }
    }

    &-details {
      .worker-info {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        &:not(:first-child) {
          margin: 20px 0;
        }

        .worker-subtitle {
          min-width: 200px;
          color: $dusty-gray
        }

        .edit-icon {
          background-color: $light-gray;
          border-radius: 50%;
          padding: 2px;
          cursor: pointer;
          color: $dusty-gray;
        }

        .worker-photo-passport-link {
          position: relative;
          width: 100px;
          height: 100px;
          border-radius: 10px;
          overflow: hidden;

          &:after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: transparent;
            background-image: linear-gradient(white, black);
            opacity: 0.6;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          p {
            position: absolute;
            width: 100%;
            margin: 0;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);
            color: #fff;
            z-index: 10;
            font-size: 10px;
            text-align: center;
          }
        }

        pre {
          margin: 0;
          white-space: pre-wrap;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5;
          letter-spacing: 0.00938em;
          margin-right: 16px;
        }
      }
    }
  }
}

@media(max-width: 998px) {
  .information-accordion {
    &-details {
      .worker-info {
        flex-wrap: nowrap;
      }
    }
  }
}

@media(max-width: 740px) {
  .information-accordion {
    &-details {
      .worker-info {
        flex-direction: column;
        align-items: flex-start !important;
      }
    }
  }
}
