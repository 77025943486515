@import './colors';

@mixin scrollbar() {
	&::-webkit-scrollbar-track {
		position: absolute;
		right: 10px;
		border-radius: 10px;
		background-color: #f5f5f5;
		margin: 5px 0 0 0;
	}

	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		//-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
		background-color: #949494;
	}
	::-webkit-scrollbar:horizontal {
		padding: 0;
	}
}

@mixin hideScrollbar() {
	&::-webkit-scrollbar-track {
		display: none;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	&::-webkit-scrollbar-thumb {
		display: none;
	}
}

@mixin pagination() {
	flex-shrink: 0;
	position: sticky;
	bottom: 0;
	background-color: $white;
}
