.MuiTooltip-popper{
  .MuiTooltip-tooltip{
    background-color: #292b2b;
  }
}

.map-info-wrapper {
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  right: 0;
  z-index: 2;
}

.map-info-item {
  display: flex;
  align-items: center;
  gap: 10px;

  .map-info-item-point {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  .map-info-item-title {

  }
}
