.iss-prices {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.payment-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    margin-left: 6px;
    color: #43A047;
  }

  .check-icon {
    stroke: #43a047;
    stroke-width: 3;
  }

  .time {
    margin-left: 12px;
    color: #9E9E9E;
  }
}
