@import 'src/styles/colors';

.worker-schedule {
  padding: 16px 24px;

  &-title {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;

    .title-text {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .days-container {
    display: flex;
    justify-content: flex-start;
    gap: 4px;
    flex-wrap: wrap;

    .day {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      min-width: 130px;

      .day-name {
        color: $manatee;
      }

      .day-ours {
        padding: 6px 8px;
        font-size: 14px;
        background-color: $selago;
        border-radius: 4px;
      }
    }
  }
}
