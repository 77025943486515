.note-issue-list {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .note-issue {
    display: flex;

    > div {
      &:first-child {
        color: rgb(138, 146, 166);
        font-size: 14px;
        min-width: 200px;
      }
    }

    .note-text {
      margin: 0;
      white-space: pre-wrap;
      font-size: 14px;
      font-weight: 500;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;

      span {
        color: rgb(138, 146, 166);
      }
    }
  }
}
