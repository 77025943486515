.user-small-data-wrapper{
  display: flex;
  .user-small-data{
    margin-left: 16px;
    .user-name{
      font-size: 14px;
      font-weight: 500;
    }
    .user-type{
      color: rgb(138, 146, 166);
        font-size: 12px;
    }
  }
}