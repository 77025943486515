.issue-status-wrapper{
  position: relative;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  .btn-status{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    color:#fff;
    border-radius: 20px;
    transition: opacity linear 300ms;
    svg{
      transition: rotate linear 200ms;
    }
  }
  .put-statuses{
    display: none;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    padding: 10px;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    right: 0;
    min-width: 200px;
    .btn-status{
      margin-top: 10px;
    }
    .btn-status:first-child{
      margin-top: 0;
    }
  }
  .put-statuses.isOpen{
    display: flex;
  }
}
