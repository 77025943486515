@import 'src/styles/colors';

.val-prop-iss {
  pre {
    margin: 0;
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
}

.modal-description-title {
  display: flex;
  align-items: center;

  .title-icon {
    margin-right: 30px;
    width: 36px;
    height: 36px;
    color: $picton-blue;
  }

  .title-text {
    font-size: 20px;
  }
}
