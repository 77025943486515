@import 'src/styles/mixins';

.messages {
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 8px;

	.delimiter {
		color: $manatee;
		text-align: center;
		margin: 8px 0;
		font-size: 12px;
	}
}
