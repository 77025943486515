.search-filters {
	position: relative;
	z-index: 3;
	margin-bottom: 14px;

	.filters-list {
		display: flex;
		gap: 10px;
		flex-wrap: wrap;
	}

	.filter-item {
		cursor: pointer;
		padding: 4px 6px 4px 8px;
		background: rgb(234, 237, 251);
		color: rgb(35, 45, 66);
		font-size: 14px;
		font-weight: 400;
		transition: color linear 300ms, background-color linear 300ms;
	}

	.filter-item.active {
		background: rgb(35, 45, 66);
		color: rgb(255, 255, 255);
	}
}
