.confirmation-content-wrapper{
  .created-data-item {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    div {
      &:first-child {
        color: rgb(138, 146, 166);
        min-width: 130px;
        text-align: start;
      }

      &:last-child {
        color: rgb(35, 45, 66);
        margin-left: 40px;
      }
    }
  }
  .client-wrapper{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    div {
      &:first-child {
        color: rgb(138, 146, 166);
        text-align: start;
        min-width: 130px;
      }
    }
  }
  .client-data{
    display: flex;
    margin-left: 40px;
    div{
      &:first-child {
        min-width: 0;
      }
    }
    p{
      margin-left: 10px;
      text-align: start;
      &:last-child{
        font-size: 12px;
        color: #949494;
      }
    }
  }
}
