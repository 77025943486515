.observe-menu {
  .MuiMenu-list {
    padding: 0;
  }

  .observe-menu-item {
    background-color: transparent !important;
    cursor: default !important;
  }
}
