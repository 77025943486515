@import 'src/styles/colors';

.worker-specializations {
  padding: 16px 24px;

  &-title {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;

    .title-text {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .specialization-options-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;

    .specialization-option {
      .specialization-sub-options-container {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;

        .specialization-sub-option {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px;
          background-color: $selago;

          .delete-button {
            height: 12px;
            width: 12px;

            &-icon {
              color: $manatee;
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
}
