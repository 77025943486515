@import 'src/styles/colors';

.reason-dialog-title {
	display: flex;
	align-items: center;

	.title-icon {
		margin-right: 30px;
		width: 36px;
		height: 36px;
		color: $picton-blue;
	}

	.title-text {
		font-size: 20px;
	}
}

.reason-form-wrapper {
	padding: 20px 0;

	.info-text {
		color: $manatee;
		font-size: 19px;
		margin-bottom: 16px;
	}

	.buttons-wrapper {
		margin-top: 16px;
		display: flex;
		gap: 8px
	}
}
