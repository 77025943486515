@import 'src/styles/colors';

.price-table {
  border: 1px solid $light-gray;
  border-radius: 10px;

  .table-head-item.table-cell {
    padding: 10px;
  }

  .table-caption {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .table-cell {
    min-width: 100px;
    font-size: 16px;

  }

  .table-cell-name {
    display: flex;
    align-items: center;
  }

  .discount {
    color: $manatee;
  }

  .warning {
    color: $gold;
  }

  .additional {
    color: $manatee;
    margin-left: 8px;
    font-size: 12px;
  }

}
