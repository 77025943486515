.status-filters-issues {
	margin-top: 15px;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	.status-filter {
		position: relative;
		cursor: pointer;
		border-radius: 10px;
		padding: 5px 10px;
		background: #fff;
		overflow: hidden;

		span {
			position: relative;
			z-index: 2;
			color: rgb(138, 146, 166);
			transition: all linear 300ms;
			&:last-child {
				font-size: 1.1rem;
				color: #282c34;
				margin-left: 25px;
			}
		}
	}

	.background {
		position: absolute;
		top: 0;
		left: 0;
		height: 5px;
		width: 100%;
		z-index: 1;
	}

	.status-filter.isActive {
		span {
			color: #fff;
		}

		.background {
			height: 100%;
		}
	}
}
