@import 'src/styles/colors';

.avatar-wrapper {
  position: relative;
  height: 111px;
  width: 111px;

  .avatar {
    height: 111px;
    width: 111px;
  }

  .edit-avatar-button-wrapper {
    position: absolute;
    bottom: 6px;
    right: 6px;

    .edit-avatar-input {
      display: none;
    }

    .edit-avatar-button {
      border: $white 1px solid;
    }
  }
}
