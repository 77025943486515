@import 'src/styles/colors';

.edit-issue-status-with-comment-form-wrapper {
  padding: 20px 0;

  .info-text {
    color: $manatee;
    font-size: 19px;
    margin-bottom: 16px;
  }
}
