.observe-filter-btn-wrapper {
  position: relative;

  .observe-wrapper {
    display: none;
  }
}

.observe-filter-btn-wrapper.isOpen {
  .observe-wrapper {
    display: block;
  }
}

.btn-filter {
  background-color: #d1d5da !important;
  color: #232d42 !important;
}

.is-active {
  background-color: #232d42 !important;
  color: #ffffff;
}

