@import 'src/styles/colors';

.service-areas {
	padding: 16px 24px;

	&-title {
		display: flex;
		align-items: center;
		gap: 16px;
		margin-bottom: 24px;

		.title-text {
			font-weight: bold;
			font-size: 20px;
		}
	}

	&-container {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
}

.service-zones {
	.heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.title {
		display: flex;
		align-items: center;
		font-weight: bold;
		font-size: 20px;
	}
	.map-wrapper {
		position: relative;
		height: 600px;
		border-radius: 5px;
		overflow: hidden;
		margin: 20px 0;

		.map-controls {
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			z-index: 10;
			max-width: 64px;
			display: flex;
			flex-direction: column;
			row-gap: 10px;
		}

		.map-edit {
			position: absolute;
			right: 10px;
			bottom: 10px;
			z-index: 10;
		}
	}
}

.working-area {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	margin-bottom: 20px;

	&-item {
		background-color: $selago;
		padding: 8px 12px;
		display: flex;
		align-items: center;
		gap: 8px;
		cursor: pointer;
	}

	&-title {
		font-size: 14px;
	}
}

.active {
	background-color: $lightBlue;
	color: #fff;
}
