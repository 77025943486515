.master-small-data {
  .master-small-data-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 420px;
    width: 100%;

    .master-small-data {
      margin-left: 16px;

      > div {
        &:nth-child(1) {
          font-size: 14px;
          font-weight: 500;
        }

        &:nth-child(2) {
          color: rgb(138, 146, 166);
          font-size: 12px;
        }

        &:last-child {
          color: rgb(138, 146, 166);
          font-size: 12px;
        }
      }
    }
  }
}
