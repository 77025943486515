@import '../../../styles/variables';

.top-bar {
	display: flex;
	align-items: center;
	height: $topBarHeight;
	padding: 0 30px;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	border-bottom: 1px solid rgb(0 0 0 / 5%);
	box-shadow: rgb(0 0 0 / 5%) 0 1px 1px 0;
	background: #fff;
	z-index: 1000;
}

.tb-logo-wrapper.side-is-open {
	width: 185px;
	min-width: 185px;
}

.tb-logo-wrapper {
	width: 50px;
	min-width: 50px;
	height: 100%;
	display: flex;
	align-items: center;
	transition: all linear 300ms;
}

.main-logo {
	position: absolute;
	opacity: 0;
	transition: all 200ms ease-in;
}

.main-logo.side-is-open {
	opacity: 1;
	transition: all 100ms ease-in-out 300ms;
}

.short-logo {
	position: absolute;
	z-index: 2;
	opacity: 1;
}

.short-logo.side-is-open {
	opacity: 0;
	transition: all 100ms ease-in-out 300ms;
}

.search-wrapper {
	padding: 0 15px;
	display: flex;
	flex-grow: 1;
	align-items: center;
}

.notifications-wrapper {
	min-width: 47px;
	display: flex;
	align-items: center;
}

.profile-wrapper {
	min-width: 160px;
	display: flex;
	align-items: center;
}
