.price-list {
  display: flex;
  margin-top: 25px;
  padding: 24px 20px;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 8px;
  background: #FFF;

  .price-item {
    display: flex;
    margin-top: 24px;
    align-items: flex-start;

    &:first-child {
      margin-top: 0;
    }

    > div {
      &:first-child {
        color: rgb(138, 146, 166);
        font-size: 14px;
        min-width: 150px;
      }

      &:last-child {
        position: relative;
        display: inline-block;
        font-weight: 500;
      }
    }

    ul, li {
      margin: 0;
      padding: 0;
    }
  }
}
