@import '../../../styles/mixins';

.observe-wrapper {
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 3;
  width: 400px;

  .scroll-wrapper {
    max-height: 350px;
    overflow-y: auto;
    @include scrollbar();
  }
}
