.iss-ad-list {
  .iss-ad-item {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    align-items: flex-start;

    &:first-child {
      margin-top: 0;
    }

    > div {
      &:first-child {
        color: rgb(138, 146, 166);
        font-size: 14px;
        min-width: 150px;
        max-width: 150px;
      }

      &:last-child {
        position: relative;
        display: inline-block;
        font-weight: 500;
      }
    }
  }

  .edit-iss-prop-icon {
    position: absolute;
    bottom: -4px;

    svg {
      font-size: 15px;
    }
  }
}
