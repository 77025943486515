@import 'src/styles/colors';

.issue-partner-prices {
  .text-info-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info-text {
      color: $manatee;
    }
  }
}
