@import '../../styles/mixins';

.prices-page-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;

	.prices-page-header {
		display: flex;
		justify-content: space-between;
	}
}

.price-page-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;

	.prices-page-header {
		display: flex;
		justify-content: space-between;
	}
}