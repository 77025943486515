@import '../../../../../styles/mixins';

.price-dialog {
	width: 100%;
	max-height: 300px;
	overflow-y: auto;
	@include scrollbar();

	.list-title {
		text-align: initial;
		color: rgba(27, 32, 64, 0.5);
		text-transform: uppercase;
	}
}
