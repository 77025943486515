@import 'src/styles/colors';

.custom-contained {
  width: 50px;
  height: 50px;
  background-color: $athens-gray !important;
  border-radius: 6px !important;
}

.options-container {
  display: flex;
  flex-direction: column;

  .option-item {
    text-transform: none;
    display: flex;
    justify-content: flex-start;
  }
}
