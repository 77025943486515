.photo-of-work-list {
	.title {
		font-size: 16px;
		margin-bottom: 10px;
	}

	.dv-photo-files {
		display: flex;
		flex-wrap: wrap;
		gap: 35px;
		margin-bottom: 10px;

		img {
			width: 100px;
			height: 100px;
			object-fit: cover;
			border-radius: 16px;
		}
	}
}
