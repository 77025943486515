.worker_verify_errors {
  li {
    padding: 0;
    color: #595959;
  }
}

.worker_verify_info {
  display: flex;
  gap: 16px;
  align-items: center;
}