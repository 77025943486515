@import 'src/styles/colors';

.worker-solved-issue-history {
  padding: 16px 24px;

  .issue-history-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 8px;
  }

  .empty-issue-history-text {
    color: $manatee;
  }
}
