.iss-wr-title {
	display: flex;
	align-items: center;
	svg {
		margin-right: 10px;
	}
}
.iss-wr-list {
	.iss-wr-item {
		display: flex;
		flex-wrap: wrap;
		margin-top: 32px;
		justify-content: space-between;
		&:first-child {
			margin-top: 0;
		}
		> div {
			&:first-child {
				color: rgb(138, 146, 166);
				font-size: 14px;
				min-width: 150px;
			}
			&:last-child {
				font-weight: 500;
				min-width: 150px;
				max-width: 300px;
			}
		}
	}
	.iss-wr-data {
		display: flex;
		flex-direction: column;
		.user-small-data-wrapper {
			margin-top: 16px;
		}
	}
}
.iss-cndts-item{
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
}

.iss-wr-controls {
	display: flex;
	gap: 16px;
}
