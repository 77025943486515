@import 'src/styles/mixins';
@import 'src/styles/colors';

.add-message-form {
	padding: 16px;
	display: flex;
	justify-content: space-between;
	gap: 16px;
	align-items: flex-end;
	height: 90px;

	.file-button {
		position: relative;

		button {
			position: relative;
			z-index: 2;
		}

		.file-input-chat {
			display: none;
		}
	}

	.text-input-wrapper {
		flex-grow: 1;
		position: relative;

		.text-field {
			width: 100%;

			& .MuiInputBase-input {
				overflow: auto;
				@include scrollbar();
			}

			.MuiInputBase-root {
				padding: 8px 14px;
			}
		}

		.send-button {
			padding: 0;
		}
	}
}

.files_popover {
	min-width: 350px;
	border-radius: 16px;
	padding: 15px;
}

.chat-label {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	cursor: pointer;
}
