@import '../../styles/mixins';
.issues-page-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.issues-page-header {
	display: flex;
	justify-content: space-between;
	// margin-bottom: 16px;
}
