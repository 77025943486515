@import 'src/styles/mixins';
@import 'src/styles/colors';

.selected-categories-container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 10px;

  .selected-category {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    background-color: $selago;

    .delete-button {
      height: 12px;
      width: 12px;

      &-icon {
        color: $manatee;
        font-size: 12px !important;
      }
    }
  }
}

