.photos {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  .photo_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
}
