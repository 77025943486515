@import 'src/styles/mixins';

.issues__content {
  list-style-type: none;
  margin: 25px 0 0 0;
  padding: 0;

  .issues__content_item {
    position: relative;
    padding: 5px 5px 5px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    cursor: pointer;

    &:has(i) {
      cursor: default;
    }

    &:hover {
      background-color: rgba(210, 210, 210, 0.5);
    }

    &.isActive {
      background-color: rgba(210, 210, 210, 0.5);
    }

    i {
      font-size: 12px;
      color: #484e52;
    }


    .issues__content_icon {
      position: absolute;
      left: 10px;
      top: 50%;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      transform: translateY(-50%);
    }

    .issues__content_link {
      span {
        text-decoration: underline;
      }
    }
  }
}

.workers__content {
  list-style-type: none;
  margin: 25px 0 0 0;
  padding: 0;

  .workers__content_item {
    position: relative;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    cursor: pointer;

    &:has(i) {
      cursor: default;
    }

    a {
      text-decoration: underline;
    }

    i {
      font-size: 12px;
      color: #484e52;
    }

    &:hover {
      background-color: rgba(210, 210, 210, 0.5);
    }

    &.isActive {
      background-color: rgba(210, 210, 210, 0.5);
    }
  }
}

.areas__content {
  list-style-type: none;
  margin: 25px 0 0 0;
  padding: 0;

  .areas__content_item {
    position: relative;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    cursor: pointer;

    &:has(i) {
      cursor: default;
    }

    i {
      font-size: 12px;
      color: #484e52;
    }

    &:hover {
      background-color: rgba(210, 210, 210, 0.5);
    }

    &.is-selected-area {
      background-color: rgba(210, 210, 210, 0.5);
    }
  }
}

.scroll-wrapper {
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  @include scrollbar();
}
