.partner-form-footer {
	margin-top: 43px;

	.footer-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 32px 27px;
		background: #e0e0f8;
		border-radius: 24px;
	}

	.info-price {
		font-weight: 600;
		font-size: 18px;
	}
}
