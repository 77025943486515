@import 'src/styles/mixins';
@import 'src/styles/colors';

.auto-wrapper {
  position: relative;
  margin-top: 10px;

  .auto-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: initial;
    width: 100%;
    background: white;
    padding: 8px 0;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;

    .auto-item {
      width: 100%;
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }
    }

    .auto-plug {
      padding: 8px 9px;
    }
  }

  .scroll-wrapper {
    max-height: 350px;
    overflow-y: auto;
    @include scrollbar();
  }
}
