.week-day-container {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin-left: 20px;
}
.edit-worker-schedule-modal-form-wrapper {
	padding: 20px 0;
	display: flex;
	flex-direction: column;
}

.time-picker-start-time,
.time-picker-end-time {
	width: 142px;
	height: 60px;
}

.time-pickers {
	padding-top: 15px;
	padding-right: 10px;
}

.add-time-container {
	display: flex;
	align-items: center;
}
