.dv-pr-list {
  .dv-pr-item {
    > div.dv-photo-files-issue {
      padding-right: 0;
      min-width: 412px;
      display: flex;
      align-items: flex-start;
      gap: 8px;

      a {
        position: relative;
        display: inline-block;
        width: 70px;
        height: 70px;
        border-radius: 6px;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.14);
        box-shadow: 0 0 8px 1px rgba(34, 60, 80, 0.2);

        &:hover {
          .dv-pf-play-icon {
            color: #7b1fa2
          }
        }
      }

      .dv-pf-play-icon {
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
        width: 30px;
        height: 30px;
        transition: all linear 200ms;
      }

      img {
        width: 70px;
        height: 70px;
      }


    }
  }
}

.dv-photo-files-preview {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 312px;
}

.dv-photo-files-controls {
  display: flex;

  .edit-iss-prop-icon {
    position: relative;
  }
}

.dv-photo-files-edit {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: 312px;

  .photo-file-wrapper {
    width: 70px;
    height: 70px;
    position: relative;
    display: inline-block;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 8px 1px rgba(34, 60, 80, 0.2);
  }

  .photo-file-delete {
    position: absolute;
    top: -13px;
    right: -12px;
  }

  .issue-photo-file-upload-input > label {
    width: 70px;
    height: 70px;
  }

  .issue-photo-file-upload-input > label > span {
    margin: 0;
  }
}
