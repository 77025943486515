@import '../../styles/mixins';

.archive-page-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;

	.archive-page-header {
		display: flex;
		justify-content: space-between;
	}

	.archive-filters-toggle-wrapper {
		margin-bottom: 24px;
	}
}
