.sidebar-tabs-map-count {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background: rgb(252, 90, 90);
  margin-left: 0;
  border-radius: 50%;
  font-size: 13px;
}
