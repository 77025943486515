.issue-page-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;

	.issue-page-header {
		display: flex;
		justify-content: flex-end;
	}

	.issue-page-content {
		display: flex;
		gap: 20px;
		overflow: auto;
		@media (max-width: 998px) {
			flex-direction: column;
		}
	}
}
