.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 48px;
  height: 48px;
  background-color: #EBEEFD;
  border-radius: 50%;
  outline: none;
  border: none;
}
