.stack-row {
  display: flex;
  align-items: stretch;

  .stack-input {
    flex-grow: 1;
  }
}

.add-button {
  justify-content: start;
}
