.toast {
    .MuiAlert-root {
      position: relative;
      padding: 16px;
      width: 427px;
      height: 88px;
      align-items: center;
      background: #DEF4E0;
      border-radius: 10px;
      box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
      &:before{
        content: "";
        position: absolute;
        left: 0;
        width: 8px;
        height: 100%;
        z-index: 1;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &_error{
        &:before{
          background: #d32f2f;
        }
      }
      &_success{
        &:before{
          background: #2e7d32;
        }
      }
      &_info{
        &:before{
          background: #0288d1;
        }
      }
      &_warning{
        &:before{
          background: #ed6c02;
        }
      }

      .MuiIconButton-root {
        position: absolute;
        top: 11px;
        right: 11px;

        svg {
          cursor: pointer;
          stroke: #CCCCCC;
          stroke-width: 3px;
        }
      }
    }
}
