@import 'src/styles/colors';

.chat-wrapper {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  .chat-header {
    padding: 15px;
    border-bottom: 1px $dusty-gray_opacity solid;
    font-weight: 500;
    font-size: 20px;
  }
  @media (max-width: 998px) {
    min-height: 600px;
  }
}
