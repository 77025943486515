.candidates-filters{
  display: flex;
  flex-wrap: wrap;
  .categories-filter-btn-wrapper,.specializations-filter-btn-wrapper{
    display: inline-block;
    margin-right: 20px;
    &:last-child{
      margin-right: 0;
    }
  }
}