.delete-group-form-wrapper {
  .delete-group-form-title {
    display: flex;
    align-items: center;
    margin-top: -20px;
  }

  .delete-group-form-description {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: start;

    p {
      margin: 0;
      padding: 0;
      color: #8a92a6;
    }

    span {
      font-weight: 700;
    }

    .buttons {
      display: flex;
      align-items: flex-start;
      margin-top: 20px;
    }
  }
}
