.worker-fio-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  .worker-fio-value {
    font-size: 20px;
    text-align: center;
  }
}
