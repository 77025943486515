.chat {
	overflow: hidden;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	&.active::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background-color: #00000040;
		z-index: 10;
		pointer-events: none;
		transition: 0.5s ease-in-out;
	}

	.messages-wrapper {
		flex-grow: 1;
		position: relative;
		overflow-y: auto;

		.empty-messages-label {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.image-preview {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #00000070;
		z-index: 10;
		cursor: pointer;

		img {
			width: 90%;
			height: 80%;
			object-fit: contain;
			cursor: initial;
		}
	}

	.close-btn {
		position: absolute;
		top: 10px;
		right: 10px;
		color: #fff;
		cursor: pointer;
	}
}
