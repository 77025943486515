.chat_header {
	display: flex;
	align-items: center;
	padding: 10px;
	column-gap: 10px;

	.chat_header_inner {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
}
