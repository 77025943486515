.workers-select-wrapper {
  margin-top: 20px;

  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  }
}
