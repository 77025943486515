@import 'src/styles/colors';

.price-inputs-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 50vh;
  overflow: auto;

  .price-input {
    background-color: $light-gray;
    padding: 16px;
    position: relative;

    &-title {
      margin-bottom: 16px;
    }

    .discount-amount {
      display: flex;
      gap: 8px;

      .amount {
        padding: 12px;

        &-title {
          color: $manatee;
          font-size: 12px;
        }
      }
    }

    .delete-button {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }
}

.item {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  &:hover{
    background-color: #f5f5f5;
  }
  .descr{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    span{
      font-size: 15px;
      color: #969daf;
    }
    svg{
      font-size: 15px;
      fill: #969daf;
    }
  }
}
