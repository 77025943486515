@import 'src/styles/variables';

.worker-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .worker-page-header {
    display: flex;
    justify-content: space-between;

    .worker-page-title {
      display: flex;
      font-size: 34px;

      &-worker-name {
        font-weight: bold;
      }
    }
  }

  .worker-page-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    overflow: auto;
  }
}
