.reminder-table-filter {
  padding: 10px;
}

.companies-table-filter {
  padding: 10px;
}

.statuses-table-filter {
  padding: 10px;
}

.help-type-table-filter {
  padding: 10px;
}

.categories-table-filter {
  .categories-wrapper {
    position: relative;
    top: 0;

    .scroll-wrapper {
      max-height: 270px;
    }
  }
}
